
import { GET_CARD_LIST } from './Card.action';

const cardList = [];

export const initialState = {
    cardList,
    isLoading: !cardList.length
};

const CardReducer = (state = initialState, action) => {
    const {
        type,
        cardList,
        status
    } = action;

    switch (type) {
        case GET_CARD_LIST:
            console.log('GET CARD LIST REDUCER', state, action);

            return {
                ...state,
                isLoading: status,
                cardList: cardList
            };

        default:
            return state;
    }
};

export default CardReducer;
