import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './LivePromo.style.scss';
import {isRoStore} from "Util/Store";

class LivePromoComponent extends PureComponent {
    static propTypes = {
        data: PropTypes.shape({
            enable: PropTypes.bool,
            title: PropTypes.string,
            title_open: PropTypes.string,
            button_url: PropTypes.string,
            items: PropTypes.arrayOf(PropTypes.shape({
                sku: PropTypes.string,
                name: PropTypes.string,
                image_url: PropTypes.string,
                price: PropTypes.number,
                product_url: PropTypes.string,
                top_product: PropTypes.bool
            }))
        }),
        currencySymbol: PropTypes.string
    };

    state = {
        isOpen: false,
        isClosed: false,
        showTopProduct: true // Added state to track visibility of top product
    };

    componentDidMount() {
        this.updateBottomPosition();
        window.addEventListener('resize', this.updateBottomPosition);
        this.updatePagePadding();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isOpen !== this.state.isOpen) {
            this.updatePagePadding();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateBottomPosition);
        this.removePagePadding();
    }

    updateBottomPosition = () => {
        const isMobile = window.innerWidth <= 768;
        if (!isMobile) {
            document.documentElement.style.setProperty('--livepromo-bottom-height', 'var(--navigation-tabs-height)');
            return;
        }

        const baseHeight = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--navigation-tabs-height')) || 0;

        const observer = new MutationObserver(() => {
            const addToCartButton = document.querySelector('.ProductActions-AddToCartWrapper');
            const checkoutButton = document.querySelector('.CartPage-Summary');
            const checkoutButtonShipping = document.querySelector('.Checkout-StickyButtonWrapper');
            const mobileAppBlockIOS = document.querySelector('.InstallPromptIOS');
            const mobileAppBlockAndroid = document.querySelector('.InstallPromptAndroid');
            let additionalHeight = 0;

            if (addToCartButton) {
                additionalHeight += addToCartButton.offsetHeight;
            }
            if (checkoutButton) {
                additionalHeight += checkoutButton.offsetHeight;
            }
            if (checkoutButtonShipping) {
                additionalHeight += checkoutButtonShipping.offsetHeight;
            }
            if (mobileAppBlockIOS) {
                additionalHeight += mobileAppBlockIOS.offsetHeight;
            }
            if (mobileAppBlockAndroid) {
                additionalHeight += mobileAppBlockAndroid.offsetHeight;
            }

            const totalHeight = baseHeight + additionalHeight;
            document.documentElement.style.setProperty('--livepromo-bottom-height', `${totalHeight}px`);

        });

        observer.observe(document.body, { childList: true, subtree: true });
    };

    updatePagePadding = () => {
        if (window.innerWidth > 768) return; // Only on mobile

        const livePromoElement = document.querySelector('.LivePromo-collapsed');
        if (livePromoElement && !this.state.isOpen) {
            const collapsedHeight = livePromoElement.offsetHeight;
            document.body.style.paddingBottom = `${collapsedHeight}px`;
        } else {
            this.removePagePadding();
        }
    };

    removePagePadding = () => {
        document.body.style.paddingBottom = ''; // Reset padding
    };

    handleToggle = () => {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen, isClosed: false }));
    };

    handleClose = () => {
        this.setState({ isOpen: false, isClosed: true }, () => {
            this.setState({ isClosed: false });
        });
    };

    handleHideTopProduct = (e) => {
        e.stopPropagation(); // Prevent triggering handleToggle
        this.setState({ showTopProduct: false });
    };

    renderCollapsed() {
        const { data: { title, items }, currencySymbol } = this.props;
        const { showTopProduct } = this.state;
        const topProduct = items.find((item) => item.top_product);

        return (
            <div className="LivePromo-collapsed" onClick={this.handleToggle}>
                <div className="LivePromo-header-row">
                    <div className="LivePromo-icon-wrapper">
                        <span className="LivePromo-icon pulsating-icon"></span>
                    </div>
                    <span className="LivePromo-text">{title}</span>
                    <span className="LivePromo-open">&#10095;</span>
                </div>
                {topProduct && showTopProduct && (
                    <div className="LivePromo-top-product" onClick={(e) => e.stopPropagation()}>
                        <button className="LivePromo-top-product-close" onClick={this.handleHideTopProduct}>&#10005;</button>
                        <img src={topProduct.image_url} alt={topProduct.name} />
                        <div className="LivePromo-item-details">
                            <span className="LivePromo-item-name">{topProduct.name}</span>
                            <div className="LivePromo-details">
                                <span className="LivePromo-item-price">
                                    {topProduct.price.toFixed(2)} {isRoStore() ? 'lei' : 'EUR'}
                                </span>
                                <a href={topProduct.product_url} className="LivePromo-detail-link">
                                    {__('See details')}
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    renderExpanded() {
        const { data: { title_open, button_url, items }, currencySymbol } = this.props;

        return (
            <div className="LivePromo-expanded">
                <div className="LivePromo-header">
                    <span className="LivePromo-title">{title_open}</span>
                    <button className="LivePromo-close" onClick={this.handleClose}>&#10095;</button>
                </div>
                <a href={button_url} className="LivePromo-main-button">{__('See all offers!')}</a>
                <div className="LivePromo-content">
                    <ul className="LivePromo-items">
                        {items.map((item) => (
                            <li key={item.sku} className="LivePromo-item">
                                <img src={item.image_url} alt={item.name} />
                                <div className="LivePromo-item-details">
                                    <span className="LivePromo-item-name">{item.name}</span>
                                    <div className="LivePromo-details">
                                        <span className="LivePromo-item-price">{item.price.toFixed(2)} {isRoStore() ? 'lei' : 'EUR'}</span>
                                        <a href={item.product_url} className="LivePromo-detail-link">{__('See details')}</a>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }

    render() {
        const { isOpen, isClosed } = this.state;
        const { data } = this.props;

        if (!data || !data.enable) {
            return null;
        }

        return (
            <div className={`LivePromo ${isOpen ? 'open' : 'collapsed'} ${isClosed ? 'closed' : ''}`}>
                {isOpen ? this.renderExpanded() : this.renderCollapsed()}
            </div>
        );
    }
}

export default LivePromoComponent;
